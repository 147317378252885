.responsiveTable3 {
    max-width: 100vw;
    // overflow: auto;
    // height: 40vh;
    position: relative;
    scroll-behavior: smooth;
    .tableWraper {
        // max-width: 100vw;
        // overflow: auto;
        // height: 83vh;
        max-width: 100vw;
        overflow-x: auto; /* Ensure horizontal scrolling */
        overflow-y: hidden; /* Prevent vertical scroll in wrapper */
        height: calc(100vh - 150px); /* Adjust based on header/footer height */
        
    }
    table {
        // thead>tr{
        //     height: 50px;
        //     align-items: center;
        // }
        thead {
            th {
                // white-space: nowrap !important; /* Prevent wrapping */
                // overflow: hidden !important;   /* Hide overflow content */
                // text-overflow: ellipsis !important; /* Add ellipsis for truncated text */
                border-right: 0px solid #fdfdfd !important; /* Optional: Vertical line */
            }
        }
        tr:hover > td {
            // background-color: #F4E5FF; //#e8e8e8;
            background-color: #d0e0ff
        }
        input[type="checkbox"] {
            cursor: pointer;
            padding: 2%;
            transform: scale(1.5);
        }
        th:first-child {
            // white-space: nowrap;
            padding-right: 0.5%;
            text-align: center;
            width: 4vw !important;
            min-width: 4vw !important;
            max-width: 4vw !important;
        }
        thead {
            position: relative;
            th {
                position: sticky;
                vertical-align: middle;
                top: 0;
                z-index: 4;
                padding-left: 0.5rem;
                padding-bottom: 0.5em;
                white-space: nowrap;
            }
            tr{
                border-bottom: 1.5px solid #35006B;
            }
        }
        td,
        th {
            min-width: 10rem;
            // position: fixed;
            // top: 0;
            height: 5vh;
            text-align: left;
            color: #fdfdfd;
            background-color: white;
            font-weight: 600;
            font-size: 1.1vw;
            .filterWraper {
                position: relative;
                margin: 0.5vw 0;
                img {
                    width: 1em;
                    height: 1em;
                    position: absolute;
                    top: 0.1em;
                    left: 0.1em;
                }
                .clearField{
                    right: 0rem;
                    top: 0.2rem;
                    width: 12px;
                    height: 15px;
                    left: auto;
                }
            }
            input[type="text"],input[type="date"],
            select {
                display: block;
                width: 90%;
                max-width: 20em;
                height: 1.36vw;
                padding: 0%;
                border: 1px solid #666666;
                outline: none;
                font-family: "Roboto";
                font-size: 0.91vw;
                color: #666666;
            }
            input[type="text"],input[type="date"] {
                padding: 0.2rem;
            }
            :last-child {
                column-span: all;
            }
        }
       
        td:last-child,
        thead,
        tbody,
        th:last-child {
            width: 100%;
        }
        // tbody{
        //     overflow-y: scroll;
        //     height: 30vh;
        // }

        tr {
            // margin: 1vw auto;
            cursor: pointer;
            .arrowContainer {
                // display: none;
                position: sticky;
                // border-top: 5px solid white;
                // border-bottom: 5px solid white;
                margin-top: 25%;
                right: 0;
                cursor: pointer;
                img {
                    vertical-align: baseline;
                }
            }
            td:first-child {
                width: 4vw !important;
                min-width: 15vw !important;
                max-width: 4vw !important;
                text-align: center;
            }

            td {
                position: relative;
                padding: 0.5%;
                // padding-left: 0%;
                text-align: left;
                // padding-left: 0%;
                white-space: nowrap;
                // background-color: #e8f2ff;
                background-color:#e8f2ff;
                line-height: 2rem;
                // margin: auto 2%;
                min-width: 10vw;
                max-width: 20vw;
                text-overflow: ellipsis;
                overflow: hidden;
                color: #333333;
                border-bottom: 5px solid white;
                font-size: 0.95vw;
                img {
                    width: 1em;
                    height: 1em;
                }
                // position: relative;
                // .toolTip{
                //     display: none;
                //     z-index: 5;
                //     padding: 2%;
                //     position: relative;
                //     white-space: normal;
                //     color: white;
                //     background-color: #262626;
                // }
            }
            // td:hover .toolTip{
            //     display: flex;
            // }
        }
    }
}

.tableWraper::-webkit-scrollbar {
    height: 12px; /* Ensure scrollbar is visible */
  }
  
  .tableWraper {
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #888 #f1f1f1; /* Custom scrollbar styling */
  }

  .contractor-list-container {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Ensure it takes full screen height */
    overflow: hidden; /* Prevent unnecessary overflow */
  }

  .ant-form-item .ant-form-item-control-input{
    min-height: 42px;
  }
  