@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
body {
    font-family: "Inter", sans-serif;
}

.flexBox {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginPageContainer {
    width: 100vw;
    display: flex;

    // ==================desinging the left side of a phone number or login Page=========================//

    .loginPageLeft {
        z-index: 1;
        width: 32rem;
        margin-left: 4rem;
        margin-top: 4rem;

        .loginPageHeader {
            font-weight: 800;
            font-size: 2.3rem;
        }

        .loginPageNotificationPage {
            h1 {
                font-family: Inter;
                font-style: normal;
                font-weight: 800;
                font-size: 1.8rem;
                line-height: 61px;
                margin-bottom: 0;
            }
            p {
                color: black;
                margin-top: -10px;
                font-weight: 500;
            }
        }

        .phoneInput {
            .selectCountryCode {
                height: auto;
                width: 21rem;
                display: flex;
                position: relative;
                justify-content: left;
                margin-top: 2rem;
                .countryCode {
                    height: 3.203rem;
                    width: auto;
                    padding: 0.5rem;
                    border-radius: 0px;
                    border: 0.5px solid #c6c6c6 !important;
                    background: #f1f1f1;
                    z-index: 6;
                    font-size: 150%;
                    outline: none;
                    border: none;
                }
                .inputPhoneNumber {
                    height: 3.203rem;
                    position: absolute;
                    width: 19rem;
                    background: #f9f9f9;
                    padding: 0.38rem 0;
                    padding-left: 3.803rem;
                    border-radius: 0px;
                    border: 0.5px solid #c6c6c6;
                    z-index: 2;
                    outline: none;
                    font-size: 150%;
                    font-family: "Inter";
                    line-height: 1.6;
                    letter-spacing: 3px;
                }
                .inputPhoneNumber:focus {
                    border-radius: 0px !important;
                    border: 0.5px solid #c6c6c6;
                }
            }
        }

        .react-tel-input {
            margin-top: 0rem;

            .form-control {
                padding-left: 25px;
                height: auto;
                width: 19rem !important;

                padding: 1rem 0.5rem !important;
                padding-left: 3rem;
                font-size: 1.15rem !important;
                border: 0.5px solid #c6c6c6 !important;
                border-radius: 0px !important;
                z-index: 2;
            }

            // .flag-dropdown {
            //     height: inherit;
            //     display: none;

            // }
        }

        .loginPageNotificationPage {
            margin-top: 4rem;
        }

        .loginPagePhoneNumber {
            position: relative;
            display: flex;

            .test {
                width: 10%;
                background-color: red;
                position: absolute;
                padding: inherit;
                margin-bottom: 0;
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            .otpInput {
                width: 4.22rem;
                height: 3.2rem;
                background-color: #666666;
                border: 0.5px solid #c6c6c6;
                margin-right: 1.86rem;
                margin-bottom: 0.3rem;
                margin-top: 2rem;
                text-align: center;
                color: #666666;
                background: #f9f9f9;
                font-size: 1.25rem;
            }
        }
        .phoneNumberSubmitButton {
            margin-top: 2.1rem;
            width: 19rem;
            height: auto;
            left: 90px;
            top: 534px;
            color: red;
            border: none;
            font-weight: 400;
            font-size: 1.5112rem;
            color: white;
            background-color: #0361fe;
            cursor: pointer;
            transition: 0.5s;
            padding: 0.28rem;
        }
        .disabled {
            color: #fff;
            pointer-events: none;
            cursor: progress;
            background-color: #e6e6e6;
            border: 1px solid #c1c1c1;
        }
        .phoneNumberSubmitButton:hover {
            // background-color: #6104a8;
            background-color: #3580FE;
        }
    }

    //====================design of Right side or Image Side================================================//
    .loginPageRight {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        width: 60%;
        height: 100vh;
        z-index: -1;
        img {
            height: 61vh;
            width: auto;
            z-index: -1;
        }
    }
}

.otpPage {
    margin-top: 0.2rem;
    width: 100vw;
    .resendingLinks {
        padding-top: 0.92rem;

        a {
            color: #0361fe;
            text-decoration: none;
        }
        :first-child {
            padding-top: 0rem;
        }
    }
}
