html, body {
    overflow: hidden;
}


.pageSecHeader{
    width: 63%;
    display: flex;
    justify-content: space-between;
}
.jobHeadBar{
    // border: 1px solid red;
    // margin: 0 1rem 0 1rem;
    margin: 1rem 1rem 0 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    .jobHeadBarLeft{
        display: flex;
        flex-direction: row;
        .ant-select-selector{
            background-color: #F3F3F3;
        }
        .ant-input{
            width: 300px;
        }
    }
}
.postModal{
    .ant-modal-close{
        margin-top: 15px;
    }
    .ant-modal-header{
        height: 8vh !important;
        border: none;
        text-align: center;
        .ant-modal-title{
            background-color: white;
            z-index: 2;
            font-size: 25px;
            margin: 2vh 0;
        }
    }
    .ant-modal-body{
        padding: 0;
        // height: 72vh !important;
        // overflow-y: auto;
        .ant-form-item{
            margin-bottom: auto;
        }

        .qualSelect{
            .ant-select-selector{
                ::-webkit-scrollbar{
                    display: none;
                }
                .ant-select-selection-overflow{

                    flex-wrap: nowrap !important;
                    overflow: auto !important;
                }
            }
        }
    }
}
.roleModal{
    // height: 80vh !important;
    width: 50vw !important;
    overflow-y: scroll;
    padding: 0 24px;
    .ant-modal-header{
        height: 8vh !important;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        .ant-modal-title{
            font-size: 25px;
        }
    }
    .ant-modal-body{
        padding: 0;
    }
}
.postFormFooter{
    width: 100%;
    padding: 0 24px;
    height: 12vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .footerbtns{
        display: flex;
    }
}
.postModalForm{
    height: 74vh !important;
    overflow-y: scroll;
    padding: 0 24px;
    .input{
        margin: 5px 0;
    }
    .postModalFirstLine{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .input{
            margin: 5px 0;
        }
        .firstLine{
            margin: 1rem 0 0.5rem; 
             
        }
    }
    .postModalDis{
        .qualification{
            display: flex;
            flex-direction: column;
        }
    }
    .postModalMiddleLine{
        margin:0.5rem 0;
        display: flex; 
        flex-direction: row; 
        justify-content: space-between;
        .leftSide{
            display: flex; 
            flex-direction: row; 
            justify-content: space-between;
            .expInp{
                width: 60%;
                margin-right: 5%;
                .wrap{
                    display: flex; 
                    flex-direction: row; 
                    justify-content: space-between;
                    // align-items: center;
                    margin-top: 0.3rem;
                    .ExpC{
                        display: flex; 
                        flex-direction: row; 
                        justify-content: space-between;
                        // align-items: center;
                        width: 50%;
                        .connector{
                            margin-top: 0.5rem;
                        }
                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button {
                          display: none;
                        }
                    }
                    .selectPer{
                        width: 43%;

                    }
                }

            }
            .dateInp{
                width: 35%;
            }
        }
        .rightSide{
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              display: none;
            }
            .salaryInp{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .inp{
                    margin-right: 5%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    // align-items: center;
                    .connector{
                        margin: 0.8rem 5% 0;
                    }
                }
                .selectPer{
                    .select{
                        width: 100%;
                    }
                }

            }
        }
    }
    .addressSel{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top:0.5rem ;
        .leftSide{
            display: flex;
            flex-direction: column;
            width: 48%;
            .input{
                width: 100%;
            }
        }
        .rightSide{
            display: flex;
            flex-direction: column;
            width: 49%;
            input{
                width: 100%;
            }
        }

    }
    .joiningFormalities{
        margin:1rem 0;
        .formalList{
            margin-top: 1rem;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            .formalListCount {
                width: 2%;
            }
            .formalListInput {
                width: 98%;
            }
        }
    
    }
}
.postModalSec{
    height: 70vh !important;
    overflow-y: scroll;
    padding: 0 24px;
    margin-top: 1rem;
    .input{
        margin: 0.5rem 0;
    }
    .queType{
        display: flex;
        flex-direction: column;
        .select{
            width: 50%;
        }
    }
}
.roleModalForm{
    height: 60vh !important;
    overflow-y: scroll;
    padding: 25px;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      display: none;
    }
    .input{
        margin: 5px 0;
    }
    .modalTop{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .modalTopLeft{
            .ant-row{
                margin-bottom: 0 !important;
            }
            .grossSalary{
                margin-top: 1rem;
                .salaryContainer{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    .salary{
                        width: 53%;
                        margin-right: 7%;
                    }
                    .salPerPeriod{
                        width: 40%;
                        // margin-left: 6%;
                    }
                }
            }
        }
        .modalTopRight{
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              display: none;
            }
            .ant-row{
                margin-bottom: 0 !important;
            }
            .descriptionContainer{
                height: 150px !important;
                .descriptionInput{
                    resize: none;
                    height: 130px !important;
                }
            }
        }
    }
    .modalMiddle{
        margin: 1rem 0;
        h4{
            text-align: center;
        }
        .splitUp{
            .ant-row{
                margin-bottom: 0rem !important;
            }
            .ant-space{
                margin: auto auto 15px auto;
            }
            margin-top: 1rem;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .container{
                width: 100%;
                margin-bottom: 10px;

                .inputContainer{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    .firstInp{
                        width: 53%;
                        margin-right: 7%;
                    }
                    .secondInp{
                        width: 40%;
                    }
                }
            }
        }
    }
}
// .postModalDis{
//     .qualSelect{
//         .ant-select-selector{
//             .ant-select-selection-placeholder{
//                 background-color: #F0F0F0;
//                 padding: 4px 10px;
//                 // display: inline-block;
//                 width: fit-content;
//             }
//         }
//     }
// }
.ant-form-item-explain.ant-form-item-explain-error {
    width: max-content !important;
}



