/* Form Container */
.Form {
    width: 90%;
    margin: auto;
  }

  .ModalContainer {
    max-height: 90vh; /* Adjust as needed */
    overflow-y: auto; /* Adds a vertical scrollbar when content overflows */
    overflow-x: hidden; /* Avoid horizontal scrolling */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensure footer stays at the bottom */
  }

  .modalFooter {
    text-align: center;
    width: 100%;
    margin: 1.78rem 0;
}

.modalFooter button {
    /* padding, width, and margin for all buttons */
    padding: 0.35rem;
    width: 6.36rem;
    margin: 0 1.25rem;
}

.modalFooter button.cancelButton {
    background: none;
    border: 0.12rem solid #0361fe;
    color: #0361fe;
    font-weight: 500;
}

.modalFooter button.saveButton {
    color: white;
    background: #0361fe;
    font-weight: 500;
    border: none;
    outline: none;
    padding: 0.45rem;
}

  
  /* Row Styling: Two Inputs Side by Side */
  .row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 15px;
  }
  
  /* Half-Width Input Fields */
  .half-width {
    width: 48%;
  }
  
  /* Full-Width Input Field */
  .full-width {
    width: 100%;
    margin-bottom: 15px;
  }
  
  /* Labels */
  .label_text {
    display: flex;
    font-weight: 500;
    margin-bottom: 5px;
  }
  
  /* Input Fields */
  .labelOnBorderInput {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  input,
  select {
    font-size: 14px;
  }
  