.table-row-light {
    border-spacing: 0 1em;
    border-collapse: separate;

    background-color: #e8f2ff !important;

    :hover {
        background-color: inherit !important;
    }
}

.table-wrapper {
    .ant-table table {
        border-spacing: 0 7px;
        border-collapse: separate;
    }
}

#description_help{
    padding-top: 85px !important;
}

/* .ant-form-item-with-help{
    top: 25px !important;
} */
 #deductions_0_type_help{
    top:24px !important;
 }

 #operationalDeduction_0_type_help{
    top:24px !important;
 }

 #earnings_0_type_help{
    top:24px !important;
 }

.ant-input-number-affix-wrapper{
    color: #81848E
}

.ant-select-prefix{
    color: #81848E !important;
}

