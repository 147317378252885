@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Roboto:wght@400&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scrollbar-width: thin;
}
::-webkit-scrollbar {
    width: 5px;
    height: 6.5px;
}
::-webkit-scrollbar-thumb {
    background-color: darkgray;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

#post-answers {
    scrollbar-width: none;
  }

  
body {
    margin: 0;
    padding: 0;
    font-family: "inter", -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: white !important;
    // overflow: auto;
}

.btn {
    font-size: 1.17vw;
    font-weight: 600;
    padding: 0.5rem 1.1rem;
    white-space: nowrap;
    background-color: transparent;
    color: #0361fe;
    outline: none;
    border: 1px solid #0361fe;
    cursor: pointer;
    height: min-content;
}
.btn-primary {
    // background-color: #0361fe;
    background-color: #0361fe;
    color: white;
    margin-left: 1rem;
}

.btn.disabled {
    color: #fff;
    pointer-events: none;
    cursor: not-allowed;
    background-color: #e6e6e6;
    border: 1px solid #c1c1c1;
}
.btn-primary:active {
    animation: btnClick 0.5s alternate;
    -webkit-animation: btnClick 0.5s alternate;
}
.btn-primary:hover {
    // background-color: #8f00ffaa;
    background-color: #007bff;
}
.flexBox{
    display: flex;
}
.flexBoxCenter{
    @extend .flexBox;
    justify-content: center;
    align-items: center;
}
.flexBoxRight{
    @extend .flexBox;
    justify-content: flex-end;
    align-items: center;
}
.flexBoxLeft{
    @extend .flexBox;
    justify-content: flex-start;
    align-items: center;
}
.flexBoxSpaceBetween{
    @extend .flexBox;
    justify-content: space-between;
    align-items: center;
}
@keyframes btnClick {
    0% {
        background-color: #0361fe;
    }
    100% {
        background-color: #007bff;
    }
}
.ant-form-item-explain div{
    color: red;
}