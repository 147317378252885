.topBar {
    background-color: #fff;
    min-width: 60px;
    height: 99vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px 8px 15px;
    border-right: 0.5px solid #666666;
   .topBox{

    .title {
        color: #0361fe;
        cursor: pointer;
        font-size: 	0.875rem;
        font-weight: 800;
    }
    .navBar{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 25px;
        img{
            margin-bottom: 20px;
        }

    }
   }    
}

