.dashBoard{
    max-width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    .leftCover{
        max-width: 50%;
        height: 100%;
        overflow-y: scroll;
        .chartHeader{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 1vw;
            padding-bottom: 0;
        }
        .chart{
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            .chartLabel{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
            }
        }
        .labels{
            display: grid;
            grid-template-columns: auto auto;
            padding: 15px;
            grid-gap: 15px;
            .labelList{
                display: grid;
                grid-template-columns: 20% 60% 20%;
                align-items: center;
                padding: 2%;
                .viewIcon{
                    display: flex;
                    justify-content: center;
                }
                .ListBullet{
                    padding-left: 5px;
                }
                p:first-child{
                    color: black;
                    font-weight: 500;
                    font-size: 1vw;
                }
                p:nth-child(2){
                    font-weight: 700;
                    font-size: 1.3vw;
                    margin-top: 3%;
                }
            }
            .bullet{
                width: 25px;
                height: 25px;
                border-radius: 50%;
            }
        }
        .massMessageBtn{
            display: flex;
            justify-content: center;
            .ant-btn{
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 100%;
                span{
                    font-size: 1rem;
                    font-weight: 500;
                    margin-right: 3%;
                }                
            }
        }
        .subscription{
            border-top: 1px solid #ccc;
            margin-top: 15px;
            padding-top: 15px;
            padding: 15px;
            .subDetail{
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                border: 1px solid #c6c6c6;
                border-radius: 10px;
                height: 20vh;

                .total{

                    border-right: 1px solid #c6c6c6;
                    width: 20%;margin: 15px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    h3{
                        color: #c6c6c6;
                        font-weight: 700;
                        font-size: 16px;
                    }
                    p{
                        font-size: 3vw;
                        // color: #40076D;
                        font-weight: 600;
                        margin-bottom: 0 !important;
                    }

                }
                .userValue{
                    width: 50%;
                    padding: 20px;
                    .subUser{
                        margin: 5% 0;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;

                        .ant-progress{
                            .ant-progress-inner{
                                .ant-progress-text{
                                    font-size: 16px;
                                    font-weight: 700;
                                }
                            }
                        }

                        h4{
                            font-weight: 600;
                            font-size: 16px;
                            color: #ccc;
                        }
                    }
                }
                .viewAllbtn{
                    width: 25%;
                }
            }
            .subReq{
                display: flex;
                padding: 15px;
                flex-direction: column;
                align-items: center;
                border: 1px solid #0361fe;
                text-align: center;
                // height: 20vh;
                border-radius: 10px;
                font-size: 18px;
            }
        }
    }
    .rightCover{
        width: 50%;
        height: 100vh;
        border-left: 0.2px solid #666666;
        .topSearchbar{
            padding: 15px;
            margin: 2vh auto;
            height: 4vh;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .title{
                h4{
                    font-weight: 700;
                }
            }
            .searchFilter{
                .ant-select.ant-select-lg.ant-select-single.ant-select-show-arrow{
                    width: 250px !important;
                    min-width: 250px !important;
                }
            }
        }
        .talentsList{
            overflow-x: hidden;
            overflow-y: auto;
            height: 92vh;
            position: relative;
            table{
                width: 100%;
                position: relative;
                thead{
                    width: 100%;
                    text-align: left;
                    // color: #7E6493;
                     color: #05246b;
                    position: relative;
                    // border-bottom: 1.1px solid #35006B;
                    border-bottom: 1.1px solid #3ea1f7;
                    tr{
                        width: 100%;
                        th{
                            width: 25;
                            font-weight: 500;
                            font-size: 80%;
                            padding: 3%;
                            padding-right: 0%;
                            position: sticky;
                            top: 0;
                            z-index: 4;
                            background-color: white;
                        }
                        th:first-child{
                           // width: 150px;
                           width: 25%;
                        }
                        th:nth-child(5){
                            width: 10%;
                        }
                    }
                }
                tbody{
                    tr{
                        // background-color: #F6EBFF;
                        background-color: #e8f2ff;
                        // border-bottom: 0.5px solid #CCA1ED;
                        border-bottom: .5px solid #3ea1f7;
                        td{
                            color: #05246b;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 90%;
                            line-height: 23px;
                            padding: 3%;
                            padding-right: 0%;
                        }
                    }
                }
            }

        }
    }
}
.massMessageModal{
    .modalHeader{
        display: flex;
        justify-content: center;
    }
    .modalFooter{
        margin-top: 3rem;
        display: flex;
        justify-content: center;
        .footerbtns{
            display: flex;
            flex-direction: row;
        }
    }
}

input[type=checkbox]{
    cursor: pointer;
}
.dropdown-check-list {
    display: inline-block;
    width: 100%;
    border: 1px solid #ccc;

    .anchor {
    position: relative;
    cursor: pointer;
    // display: inline-block;
    padding: 10px 15px 10px 10px;
    // border: 1px solid #ccc;
    width: 100%;
    font-size: 16px;
    color: #c6c6c6;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    
    }
    
    .items {
    margin-top: 10px;
    width: 100%;
    position: absolute;
    z-index: 100;
    background-color: white;
    padding: 2px;
    display: none;
    border: 1px solid #ccc;
    height: 25vh;
    overflow-y: scroll;
    box-shadow: 0px 0px 5px #ccc;

    .option{
        .inputBox{
            display: flex;
            align-items: center;

        }
        #employerList{
            margin-top: 5px;
            margin-left: 10px;
        }
    }

    .checkBox{
        height: 20px;
        width: 20px;
        accent-color: #0361fe;
    }
  }
}
.dropdown-check-list ul.items li {
    list-style: none;
    background-color: white;
    padding: 5px;
    input{
        background-color: white;
    }
    span{
        margin-left: 10px;
        font-size: 16px;
    }
    .head{
        font-size: 14px;
    }
    .option{
        margin-top: 5px;
        margin-left: 10px;
        li{
            display: flex;
            align-items: center;
        }
    }
}  
.dropdown-check-list.visible .items {
    display: block;
}
