.inputWrapper {
    position: relative;
    margin-bottom: 20px;
  }
  
  .labelOnBorderInput {
    width: 100%;
    padding: 10px 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
  }
  
  .labelOnBorderInput:focus {
    border-color: #007bff;
  }
  
  .floatingLabel {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    background-color: white;
    padding: 0 5px;
    color: #777;
    pointer-events: none;
    transition: 0.2s ease all;
  }
  
  .labelOnBorderInput:focus + .floatingLabel,
  .labelOnBorderInput:not(:placeholder-shown) + .floatingLabel {
    top: -10px;
    left: 10px;
    color: #007bff;
    font-size: 12px;
  }

  
  .has-value {
    outline: 2px solid blue; /* Customize the outline color and thickness */
  }