.trible_btn{
    background: #0361fe;
    text-align: center;
    height: 100%;
    padding: 0rem 0.5rem;
    margin-left: 1rem;
    outline: none;
    border: none;
    cursor: pointer;
    .trible_dot{
        width: 100%;
    }
}