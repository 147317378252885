.job-post-details {
    padding: 24px;
    background-color: #fff;
    min-height: 100vh;
}


.details-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
}

.not-found-container {
    text-align: center;
    padding: 50px;
}

.details-grid {
    margin-bottom: 24px;
}

.grid-row {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 24px;
    margin-bottom: 24px;
}

.grid-item {
    font-size: 10px !important;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.label {
    color: #666;
    font-size: 14px;
    font-weight: 500;
}

.value {
    font-size: 20px;
    color: #000;
}

.highlight {
    color: #1677ff;
}

.expandable-sections {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
}

.section {
    background-color: #f5f8ff;
    border-radius: 0px;
    padding: 16px;
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.section-header h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
}

.section-content {
    margin-top: 10px;
    color: rgb(53, 0, 107);
    font-weight: 500;
    font-size: 14px;
    line-height: 2.5;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .grid-row {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {
    .grid-row {
        grid-template-columns: repeat(2, 1fr);
    }

    .expandable-sections {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 480px) {
    .grid-row {
        grid-template-columns: 1fr;
    }
}

.ant-table-thead>tr>th {
    position: sticky !important;
    top: 0 !important;
    z-index: 5 !important;
    background: #FFFFFF !important;
    font-weight: 500;
    border-bottom: 1px solid #E5E7EB;
}

.ant-table-tbody>tr:nth-child(odd) {
    background-color: #F8FAFF;
}

.ant-table-tbody>tr:nth-child(even) {
    background-color: #ffffff;
}

.ant-table-tbody>tr:hover>td {
    background-color: #F0F7FF !important;
}

.ant-table-tbody>tr:hover {
    cursor: pointer;
}

/* Search input styles */
.ant-input-search {
    width: 200px;
}

/* Select styles */
/* .ant-select {
    min-width: 150px;
} */

/* Button styles */
.ant-btn-primary {
    height: 32px;
    padding: 0 16px;
}

.form-field-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.form-field-container label {
    color: #6B7280;
    font-size: 14px;
}

.form-item-with-error {
    margin-bottom: 0;
}

.form-item-with-error .ant-input-number,
.form-item-with-error .ant-select-selector {
    width: 100% !important;
    border-radius: 0px;
}

.form-item-with-error .ant-input-number:hover,
.form-item-with-error .ant-select-selector:hover {
    border-color: #d9d9d9;
}

.form-item-with-error .ant-input-number-focused,
.form-item-with-error .ant-select-focused .ant-select-selector,
.form-item-with-error .ant-input-number:focus,
.form-item-with-error .ant-select-selector:focus {
    border-color: #4096ff;
    box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
}

.form-item-with-error.ant-form-item-has-error .ant-input-number,
.form-item-with-error.ant-form-item-has-error .ant-select-selector {
    border-color: #ff4d4f;
}

.form-item-with-error.ant-form-item-has-error .ant-input-number:hover,
.form-item-with-error.ant-form-item-has-error .ant-select-selector:hover {
    border-color: #ff4d4f;
}

.form-item-with-error .ant-input-number-prefix {
    color: #6B7280;
    margin-right: 4px;
}

.ant-select-dropdown {
    border-radius: 0px;
}

.ant-select-item {
    padding: 8px 12px;
}

.ant-select-item-option-selected {
    background-color: #f0f7ff;
}

.jobpost-modal {
    top: 20px !important;
    height: 90% !important;
    overflow: hidden !important;
    .ant-modal-content{
        border-radius: 0px;
        height: 100%;
    input{
        height: 40px !important;
    }
    .ant-select-selector{
        height: 40px !important;
    }
    }
}

.form-field-container label{
    margin-bottom: 0px !important;
}

.form-field-container {
    /* margin-bottom: 8px !important; */
    margin-bottom: 20px !important;
    .ant-form-item{
        margin-bottom: 0px !important;
    }
}

.jobTableList{
    margin-top: 1rem !important;
    height: 84.9vh !important;
    overflow-y: auto !important;
    scrollbar-width: none !important;
    overflow-x: auto !important;
    position: relative !important;
    table{
        td.ant-table-cell{
            border-bottom: 0.5px solid #3ea1f7 !important;
            padding: 0 !important;
            padding-right: 20px !important;
        }
        width: 100% !important;
        thead{
            /* position: sticky !important; */
            top: 0 !important;
            :nth-child(1){
                text-align: center !important;
                /* width: 15px !important; */
                margin-left: 10px !important;
            }
            .id{
                padding-left: 15px !important;

            }
            th {
            width: max-content !important;
            font-weight: 500 !important;
            font-size: 13px !important;
            padding: 15px 0 !important;
            z-index: 4 !important;
            background-color: white !important;
            text-align: left !important;
            border-bottom: 0.5px solid #3ea1f7 !important;
            color: #81848E !important;
        }
        }
        tbody{
            overflow-x: auto !important;
            :active{
                background-color: #F4E5FF !important;
            }
            tr{
                td{
                    padding-right: 15px !important;
                }
                .classId{
                    min-width: 120px !important;
                }
                :nth-child(1){
                    text-align: center !important;
                }
                :nth-child(2){
                    min-width: 180px !important;
                }
                :nth-child(4), :nth-child(5){
                    min-width: 180px !important;
                }
                :nth-child(6){
                    min-width: 210px !important;
                }
                :nth-child(8){
                    min-width: 100px !important;
                    text-align: center !important;
                }
                .descriptionData{
                    padding-right: 20px !important;
                    width: 100% !important;
                    span{
                        text-align: left !important;
                        text-overflow:ellipsis !important;
                        overflow:hidden !important;
                        display: -webkit-box !important;
                        -webkit-line-clamp: 2 !important;
                        -webkit-box-orient: vertical !important;
                    }

                    white-space: normal !important;
                }
                height: 12vh !important;
                background-color: #e8f2ff !important;
                border-bottom: 0.5px solid #3ea1f7 !important;
                font-size: 15px !important;
                font-weight: 500 !important;
                color: #05246b !important;
                cursor: pointer !important;

                :last-child{
                    text-align: left !important;
                    /* padding-right: 10px !important; */
                }
            }
        }
    }
}

.ant-select .ant-select-selection-wrap{
    height: 35px !important;
}
.ant-select-multiple .ant-select-selection-overflow-item{
    height: 20px;
}
