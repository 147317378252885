.container {
  display: flex;
  flex-wrap: wrap;
  /* gap: 12px; */
  padding: 20px;
  justify-content: space-between;
  height: calc(100vh - 40px); /* Subtract padding */
}

.card {
  width: 49%;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.card:hover {
  transform: translateY(-5px);
}

/* Left card specific styles */
.card:first-child {
  overflow-y: auto;
}

.card:first-child form {
  flex: 1;
  overflow-y: auto;
  padding-right: 10px; /* Space for scrollbar */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.card:first-child form::-webkit-scrollbar {
  width: 6px;
}

.card:first-child form::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.card:first-child form::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.card:first-child form::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* History card styles */
.history-card {
  overflow-y: auto;
  max-height: 100%;
}

.history-content {
  padding: 10px;
  color: #555;
  font-size: 14px;
  overflow-y: auto;
  max-height: 100%;
}

.heading {
  color: #333;
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: 600;
  position: sticky;
  top: 0;
  background: #fff;
  padding: 10px 0;
  z-index: 1;
}

.subheading {
  color: #555;
  font-weight: 500;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0 10px;
  position: sticky;
  bottom: 0;
  background: #fff;
  z-index: 1;
}

/* Responsive Design */
@media (max-width: 768px) {
  .container {
    height: 100vh;  /* Full viewport height */
    overflow-y: auto; /* Enable vertical scrolling */
    padding: 10px;
  }

  .card {
    width: 99%;
    max-height: none;
  }

  .card:first-child {
    height: auto;
    max-height: none;
  }

  .history-card {
    max-height: 400px;
  }
}