.tab-container-vmm {
    display: flex;
    flex-direction: row;
    border-bottom: 1.5px solid #c6c6c6;
    margin-bottom: 2rem;
    .tabs:hover {
        color: #9000ffd3;
    }
    .tabs {
        // font-family: "Inter";
        color: #81848e;
        font-style: normal;
        font-weight: 600;
        cursor: pointer;
        font-size: 1rem;
        margin: 0 1em;
        padding: 0.5em 0;
        margin-bottom: -3px;
    }
    .tabs.active {
        color: #0361fe;
        border-bottom: 6px solid #0361fe;
    }
}

.Filterscroll{
  // height: 18rem;
  // width: 100%;
  // overflow-y: scroll;
  // padding: 0 25px 0 0;
  height: 44vh !important;
  overflow-y: scroll;
  padding: 0 24px;
  margin-right: -24px;
 
  .input{
    margin: 5px 0;
  }
}

::-webkit-scrollbar {
  width: 2px;
 }


.filterIcon {
    background-color: #0361fe;
    right: -2rem;
    position: absolute;
    cursor: pointer;
}
.filterIcon:hover {
    box-shadow: 0px 0px 10px #8f00ff99;
}
.batchOperation {
    display: flex;
    // margin: 0% 5%;
    height: fit-content;
    justify-content: flex-end;
}
.panelHeading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2%;

    .heading {
        font-size: 1.82vw;
        font-weight: 600;
        color: #333;
    }

    > div {
        display: flex;
        justify-content: flex-end;
    }
}
.searchwraper{
    .ant-input-group-wrapper{
        .ant-input-wrapper {
            .ant-input-affix-wrapper{
                :focus{
                    border-color: initial !important;
                    box-shadow: none !important;
                }
            }
        }
    }
}

.search .searchwraper {
    // width: 28%;
    // // padding-left: 0.2rem;
    position: relative;
    display: flex;
    justify-content: left;
    align-items: center;

}
//  changes end

//===============.modalClass designs for detach and enroll==================//

.modalClass {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      display: none;
    }
    .ant-modal-content {
        .enrollModal {
            width: 100%;
            height: auto;
            .autoComplete {
                width: 100% !important;
            }
            &_Header {
                h3 {
                    font-size: 1.24495;
                    font-weight: 600;
                    font: "Inter";
                }
            }
            &Body {
                color:#333;
                display: flex;
                .count {
                    background: #0361fe;
                    width: 20px;
                    height: 20px;
                    text-align: center;
                    margin-left: 10px;
                    color: white;
                    margin-bottom: 1.7785rem;
                }
            }
            &Input {
                position: relative;
                .enrollModalInput {
                    position: absolute;
                    z-index: 2;
                    top: -10px;
                    left: 10px;
                    background: white;
                    color: #666666;
                    padding: 0 5px;
                }
            }
            .enrollEmppar{
                display: flex;
                flex-direction: row;
                 justify-content: space-between;
 
                 .shiftEndTime{
                     width: inherit !important;
                 }
                 .shiftStartTime{
                     width: inherit !important;
                 }
                 .enrollleft{
                    .ant-row{
                        margin-bottom: 0;
                    }
                     display: flex;
                     flex-direction: column;
                     width: 48%;
                     .input{
                         width: 100%;
                     }
                 }
                 .enrollright{
                    .ant-row{
                        margin-bottom: 0;
                    }
                     display: flex;
                     flex-direction: column;
                     width: 49%;
                     input{
                         width: 100%;
                     }
                 }
	         
            }
            &Buttons {
                width: 100%;
                display: flex;
                justify-content: center;
                .btn {
                    padding: 0.35rem;
                    width: 6.36rem;
                    margin: 0 1.25rem;
                    margin-top: 1.7785rem;
                }
                &Cancel {
                    @extend .btn;
                    background: none;
                    border: 1.5px solid #0361fe;
                    color: #0361fe;
                }
                &Enroll {
                    @extend .btn;
                    background: #0361fe;
                    color: white;
                    border: none;
                    padding: 0.45rem !important;
                }
            }
        }
        //============ start of detech modal design========//
        .detachModal {
            width: 100%;
            height: auto;
            &Header {
                h3 {
                    font-size: 1.244rem;
                    font-family: "Inter";
                }
            }
            &Body {
                display: flex;
                padding-top: 1.778rem;
                .count {
                    background: #0361fe;
                    width: 20px;
                    height: 20px;
                    text-align: center;
                    margin-left: 10px;
                    color: white;
                }
            }
            &Form {
                .row{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .column{
                        width: 47%;
                    }
                }
            }
            &Buttons {
                width: 100%;
                display: flex;
                justify-content: center;
                .btn {
                    padding: 0.35rem;
                    width: 6.36rem;
                    margin: 0 1.25rem;
                    margin-top: 1.7785rem;
                }
                &Cancel {
                    @extend .btn;
                    background: none;
                    border: 1.5px solid #0361fe;
                    color: #0361fe;
                }
                &Detach {
                    @extend .btn;
                    background: #0361fe;
                    color: white;
                    border: none;
                    padding: 0.45rem !important;
                }
            }
        }
    }
}

//====================.modalClass designs for detach and enroll ends============//

 
.enrollscroll{
    height: 58vh !important;
    overflow-y: scroll;
    padding-right: 24px;
    margin-right:-24px;
}
.tabSlider {
    .ant-tabs-nav {
        margin-left: 1% !important;
        margin-bottom: 25px !important;
        .ant-tabs-tab {
            font-size: 1.04vw !important;
            padding: 10px 0 !important;
        }
    }
    // .ant-tabs-nav .ant-tabs-tab{
    // }
    .ant-tabs-tab-btn {
        font-weight: 600;
    }
    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
        height: 4px !important;
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
    }
    .ant-tabs-nav-wrap {
        border-bottom: 1px solid #c6c6c6;
    }
}
.filter_title{
    text-align: center;
    margin: 3vh 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
}

.line{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .left{
        width: 50%;
        margin-right: 20px;
    }
    .right{
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // margin-left: 10%;
        .expInp{
            // width: 60%;
            // margin-right: 5%;
            .wrap{
                display: flex; 
                flex-direction: row; 
                justify-content: space-between;
                // align-items: center;
                margin-top: 0.5rem;
                .ExpC{
                    display: flex; 
                    flex-direction: row; 
                    justify-content: space-between;
                    // align-items: center;
                    // width: 50%;
                    .connector{
                        margin-top: 0.5rem;
                        margin-left: 4px;
                        margin-right: 4px;
                    }
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                      display: none;
                    }
                }
                .selectPer{
                    width: 43%;

                }
            }

        }
    }
}
.footer_btn{
    display: flex;
    justify-content: center;
    height: 10vh;
    .btn{
        padding: 0px 28px;
        border: 1px solid #000000;
    }
}
.search {
    position: relative;
    margin: 1%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    

    .searchWraper {
        background-color: #f7f7f7;
        border: 0.5px solid #c6c6c6;
        width: 28%;
        display: flex;
        align-items: center;
        // .searchIcon {
        // position: absolute;
        // top: 25%;
        // left: 0.2%;
        // }
        .searchBox {
            outline: none;
            border: none;
            color: #808080;
            background-color: #f7f7f7;

            font-size: 1.17vw;
            font-weight: 600;
            padding: 0.5%;
            width: 90%;
            padding-left: 2%;
        }
    }
    .catSelection .ant-select-selector {
        background-color: #f7f7f7 !important;
    }
    .searchRight{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

    }
}
.listTable {
    table {
        .ant-checkbox-inner {
            border: 1px solid #000;
            border-radius: 3px;
        }
        .rowSpace {
            background: #f9f9f9;
            cursor: pointer;
            // :hover{
            //     background-color: #F9F9F922;
            // }
            td {
                border-bottom: 5px solid white;
                border-collapse: separate;
                background-color: #f7f7f7;
            }
        }
    }
}

//============Modal design=============//
.contractorModal{
    width: 55vw !important;
    // height: 20vw !important;
//  scroll-margin: auto;
 .Form
 {
    height: 50vh;
    overflow-y: scroll;


 }
 .Form{
    -ms-overflow-style: none;
}
::-webkit-scrollbar {
    display: none;
}


    .Compay-Address,.Vendor-Registered,.Pan-Gstin,.Epf-Esic,.Pt-Tan
    {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0.3rem;
    }

    .Mob-Email-Selected
    {
        width: 48%;
    }
    .selected-Option
    {
        display: flex;
    }

    .phoneNumber-Input
    {
        border: 1.5px solid #c6c6c6;
        width: 90%;
        // padding: 0.25rem;
        color: #8c8c8c;
        box-sizing: border-box;
        height: 2rem;
        outline: none;
        &:hover {
            border: 0.015rem solid#0361fe;
        }
    }
    

}
.modalClass {
    background-color: white;
    width: 33.92rem;
    .labelOnBorderInput {
        border: 1.5px solid #c6c6c6;
        width: 100%;
        padding: 0.25rem;
        color: #8c8c8c;
        box-sizing: border-box;
        height: 2.4rem;
        outline: none;
        &:hover {
            border: 0.015rem solid#0361fe !important;
        }
    }

    & h3 {
        text-align: center;
        font-size: 1.65rem;
        font-weight: 600;
    }

    .modalMiddleLineInput {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 2rem 0;

        .ModalphoneNumberInput {
            width: 48%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            position: relative;

            .contryCode {
                width: auto;
                position: absolute;
                z-index: 2;

                span.ant-select-arrow {
                    display: none;
                }

                .ant-select-selector {
                    background-color: #f1f1f1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 2.4rem;
                }
            }
            .phoneNumberInput {
                height: 2.4rem;
                padding-left: 4.5rem;
                border: 1.5px solid #c6c6c6 !important;
                &:hover {
                    border: 0.015rem solid#0361fe !important;
                }
                &:focus {
                    border: 1.5px solid #c6c6c6 !important;
                    outline: none;
                    box-shadow: none !important;
                }
            }
        }
        .modalUserTypeSelect {
            width: 48%;
            height: 2.4rem;
            outline: none;

            .userSelectors {
                width: 100%;
                height: 2.4rem;
                border: 1.5px solid #c6c6c6;
                cursor: pointer;

                &:hover {
                    border: 0.015rem solid#0361fe;
                }
                &:focus {
                    outline: none;
                }
            }
        }
    }
    .bottomLine {
        position: relative;
        &Input {
            color: #808080 !important;
            font-size: 1rem;
        }
        &Label {
            position: absolute;
            top: -15px;
            left: 10px;
            background: white;
            padding: 0 15px;
        }
    }

    .modalFooter {
        text-align: center;
        width: 100%;
        margin: 1.78rem 0;
        & button {
            // background: rgb(168, 168, 228);
            padding: 0.35rem;
            width: 6.36rem;
            margin: 0 1.25rem;

            &.cancelButton {
                background: none;
                border: 0.12rem solid #0361fe;
                color: #0361fe;
                font-weight: 500;
            }
            &.saveButton {
                color: white;
                background: #0361fe;
                font-weight: 500;
                border: none;
                outline: none;
                padding: 0.45rem;
            }
        }
    }

    .ant-modal-header {
        display: none;
    }
    .ant-modal-footer {
        display: none;
    }
    .AddTalentContainer {
        background-color: rgb(255, 255, 255);

        .talentHeader {
            font-size: 1.24rem;
            font-weight: 650;
        }
        p {
            font-weight: 500;
        }

        .buttonClass {
            border: 2px dashed #0361fe;
            //background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");            box-sizing: border-box;
            height: 6.67rem;
            width: 16.81rem;
            background: #808080;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background: none;
            margin-top: 0.889rem;
            margin-left: 0.6rem;
            color: #0361fe;
            font-weight: 500;
            cursor: pointer;
            &:hover {
                border: 2px dashed #0361fe;
            }
            img {
                height: 1.4445rem;
            }
        }
        .downLoadButton {
            @extend .buttonClass;
            margin-bottom: 1.778rem !important;
        }
        .uploaddButton {
            border: 2px dashed #707070;
            @extend .buttonClass;
            color: #000;
        }
    }
}
.ant-modal.modalClass {
    background: none;
}

.ModalphoneNumberInput:hover ~ .ant-select-selector {
    background: red;
}

.searchOption{
    width: 200px !important
}

// .modalClass{

//         justify-content: center;
//         align-items: center;
//         width: 250px;
//      .form{
//         position: relative;
//         padding-top: 2rem;
//         background-color: rgb(39, 8, 68);
//         height: 300px;
//         &_Input{
//             background-color: lightblue;
//             position: absolute;
//             left: 2rem;
//             border: .15rem solid rgb(246, 101, 101);
//             border-radius: .25rem;
//             color: lightcyan;
//             background: none;

//             &:hover{
//                 border: .25rem solid rgb(126, 2, 2);

//             }
//             &:focus{
//                 background-color: khaki;
//                 border: .25rem solid rgb(126, 41, 2);
//                 color:rgb(98, 19, 19)
//             }
//         }
//         &_label{
//             color: white;
//             position: absolute;
//             left:2.5rem;
//             cursor: text;
//             top:2.2rem;
//             transition: top 200ms ease-in,
//                 left 200ms ease-in,
//                 font-size 200ms ease-in;
//             background: rgb(39, 8, 68);
//             padding:0 5px;
//         }
//     }
// }

// .form_Input:focus ~ .form_label{
//     top: 20px;
// }
// changes

.footer_btn .ant-form-item{
    margin-bottom: 0%;
}
.ant-form-item-explain-error{
    white-space: pre;
    color: red;
    margin: 6px 0;
}
.tabBtn{
    padding: 0.6rem !important;
}

.ant-badge{
    margin-top: -40px !important;
    position: relative;
}

.ant-badge-dot{
    width: 10px !important;
    height: 10px !important;
}

.Pt-Tan {
    .firstLine{
        .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector{
            border-color: #c6c6c6;
        }
        .ant-select-selection-placeholder{
            color: #8c8c8c;
            font-size: 14px;
        }
    }
}