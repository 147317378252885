.container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // padding: 2%;

  .detailsPageHeader {
    width: 100%;
    display: flex;
    height: 15%;
    padding: 2%;
    justify-content: space-between;

    .backBtnContainer {
      display: flex;
      margin-bottom: 3%;
      align-items: center;
    }
    .image {
      cursor: pointer;
      margin-right: 1%;
    }
    .btntop {
      width: 300px;
      margin-left: 3%;
      margin-bottom: 0;
    }
    .buttons {
      display: flex;
      margin-right: 2%;
      .reject {
        width: 50%;
        margin-right: 5%;
      }
      .accept {
        background-color: #0361fe;
        color: #fff;
      }
    }
  }
  .enrolldetailscroll {
    height: 85%;
    overflow-y: auto;

    .enrolldetails {
      height: 20%;
      width: 100%;
      padding-left: 2%;
      display: flex;
      flex-wrap: wrap;

      .reqname {
        width: 15%;
      }
      .icon {
        margin-left: 5%;
        width: 9%;
      }
    }
    .enrollnotes {
      margin-top: 2%;
      margin-left: 2%;
      background-color: #fcf8ff;
      padding: 16px;
      padding-left: 20px;
      padding-bottom: 5px;
      border-radius: 10px;
      width: 95%;
      border: 1px solid #0361fe;
      overflow: hidden;
      min-width: 250px;

      .notesheader {
        font-weight: bolder;
      }
    }

    .enrolldetailtable {
      margin-top: 2%;
      .heading{
        margin-left: 2%;
      }
    }
  }
}

  .popupoverenroll{
    .ant-popover-arrow{
      .ant-popover-arrow-content{
        background-color: black;
      }
    }
    .ant-popover-inner {
      .ant-popover-inner-content{
      background-color: black;
      color: white;
    }
  }
}

.contractPage{
  padding-left: 25px !important;
}

.tableContainer{
  .jobTableList{
    margin-top: 1rem;
    height: 81.2vh;
    overflow-y: auto;
    position: relative;
    table{
      width: 100%;
      thead{
        width: 100%;
        font-weight: 500;
        font-size: 14px;
        z-index: 4;
        background-color: white;
        // text-align:center;        
        border-bottom: 0.5px solid #35006B;
        tr {
          th{
            padding-bottom: 10px;
          }
          th {:nth-child(1){
              // text-align: center ;
              width: 10px;
              // margin-left: 10px;
            }
          }
        }
      }
      tbody{
          overflow-x: auto;
          :active{
              background-color:  #0361fe;
          }
          tr{
              td{
                  text-align: center;
                  padding-right: 15px;
              }
              :nth-child(1){
                  min-width: 20px;
              }
              :nth-child(2){
                  min-width: 100px;
              }
              :nth-child(4){
                min-width: 120px;
              }
              :nth-child(5){
                  min-width: 130px;
              }
              :nth-child(6){
                  min-width: 10px;
              }
              :nth-child(7){
                min-width: 180px;
              }
              :nth-child(8){
                  min-width: 180px;
                  // text-align: center;
              }
              :nth-child(9){
                min-width: 180px;
                // text-align: center;
              }
              .data{
                  // padding-right: -90px;
                  margin-top: 20px;
                  width:20%;
                  span{
                      // text-align: left;
                      text-overflow:ellipsis;
                      overflow:hidden;
                      // Addition lines for 2 line or multiline ellipsis
                      display: -webkit-box !important;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                  }
                  white-space: normal;
              }
              height: 8vh;
              background-color: #e8f2ff;
              border-bottom: 0.5px solid #CCA1ED;
              font-size: 14px;
              font-weight: 500;
              color: #4E1F7E;
              cursor: pointer;
              :last-child{
                  padding-right: 10px;
              }
          }
      }
    }
  }
}
