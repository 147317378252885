.popupImgContainer{
    position: fixed;
    display: flex;
    background: #00000075;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    z-index: 5;
}