a {
    color: inherit;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    padding: 0;
    margin: 0;
}
.imgPopup {
    cursor: pointer;
}

.pageContainer {
    background-color: white;
    // position: absolute;
}
.topNavSection {
    display: flex;
    justify-content: space-between;
    padding: 1vw 2vw 0vw 2vw;
    a {
        font-weight: 600;
        color: #000;
        font-size: 1.82vw;
        display: flex;
        justify-content: space-between;
        align-items: center;

        //     .leftArw{
        // // font-weight: 900;
        // // font-size: 3vw;
        //     }
        img {
            vertical-align: baseline;
            padding-right: 1rem;
            max-width: 2vw;
            min-width: 1.5rem;
            // height: 0.712vw;
        }
    }
    a:hover {
        color: #000;
    }
    .action {
        flex-direction: row;
        display: flex;
        justify-content: flex-end;
        height: fit-content;
        button {
            margin: auto 3%;
            padding: 1% 15% !important;
            height: 2.6vw !important;
        }
    }
}
.bodyMain {
    // border: 1px solid red;
    margin: 1vw 2vw 0vw 2vw;
    margin-top: 0;
    display: flex;
    flex-direction: row;
    position: fixed;
    height: 100%;
    width: 92%;
    .split {
        flex: 1;
    }
    .split.leftSide {
        flex: 0.25;
        background-color: white;
        box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.05);

    }
    .secHead {
        margin: 0;
        padding: 0;
        font-size: 1.56vw;
        padding-left: 1vw;
        padding-top: 0.5vw;
        padding-bottom: 1rem;
        font-weight: bold;
    }
    .profile {
        margin-top: 2.5rem;
        text-align: center;
        img {
            width: 8.26vw;
            height: 8.26vw;
            border: 2px solid #ffffff;
            border-radius: 10px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            -ms-border-radius: 10px;
            -o-border-radius: 10px;
        }
        .proNameSec {
            text-align: center;
            .empName {
                font-size: 1.43vw;
                font-weight: 600;
                margin: 0;
                padding: 0;
            }
            .badge {
                font-size: 0.911vw;
                color: #77888b;
            }
        }
    }
    .userInfoContainer {
        border: 1px dashed #d7d6d6;
        background-color: #fafafa;
        margin: 0.5vw 1vw;
        padding: 0.5rem 2vw;
        .detailsSec {
            margin: 1rem auto;
            .labelField {
                font-size: 1.04vw;
                color: #77888b;
            }
            .fieldDetail {
                font-size: 1.17vw;
                font-weight: 600;
                color: rgba(34, 34, 34, 0.72);
            }
        }
    }
}

.split.right {
    height: 100vh;
    padding-left: 2%;
    scrollbar-width: none;
    overflow-y: scroll;
    .tabContainer {
        border-bottom: 1px solid #c6c6c6;
        display: flex;
        box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.05);
        // background-color: #ffffff;
        flex-direction: row;
        overflow-y: scroll;
        scrollbar-width: none;
        .tabItem {
            color: #81848e;
            font-weight: 600;
            font-size: 1.17vw;
            margin: auto 1rem;
            padding: 0.5rem 0;
            cursor: pointer;
        }
        .active {
            border-bottom: 3px solid #0361fe;
            color: #0361fe;
        }
    }
    .detailsContainer {
        background: #ffffff;
        box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.05);
        padding: 1% 2%;
        margin: 1% auto;
        max-height: 17rem;
        overflow-y: auto;
        position: relative;
        // >h4{
        //     position: sticky;
        //     top:0;
        //     background-color: #ffffff;
        // }
        .cardDetailsHead {
            color: #222222;
            font-size: 1.3vw;
            font-weight: bold;
        }
        .innerContainer {
            padding: 1.8% 2%;
            background: #fafafa;
            border: 1px dashed #d7d6d6;
            display: grid;
            grid-template-columns: 20% 20% 20% 20% 20%;
            // justify-content: space-around;
            // flex-direction: row;
            .fieldData {
                padding: 0% 2%;
                min-height: 84px;
                .labelField {
                    color: #77888b;
                    font-size: 1.04vw;
                }
                .fieldDetail {
                    color: rgba(34, 34, 34, 0.72);
                    font-size: 1.17vw;
                    font-weight: 600;
                    overflow-wrap: break-word;
                    img {
                        width: 6.64vw;
                        height: 3.9vw;
                        border: 2px solid #ffffff;
                    }
                }
            }
        }
    }
}
