.jobList{
    width: 100%;
    height: 100vh;
    overflow-y: hidden;

    .topTab{
        .tabs{
            font-size: 1.3vw !important;
            color: #81848e;
            font-style: normal;
            font-weight: 600;
            font-size: 1rem;
            margin-bottom: -3px;
            
        }
    }
    .ant-tabs-nav{
        border-bottom: 1.5px solid #c6c6c6;
    }
}
.ant-modal-footer {
    gap:  20px;
    height: 100px;
    align-items: center;
    display: flex;
    justify-content: center;
    border-top: none !important;
}
.ant-tabs-nav{
    margin-left: 1rem !important;
}
// .ant-select-dropdown {
//     width: fit-content !important;
//     min-width: fit-content ;
// }


