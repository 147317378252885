// .ant-modal-wrap:has(div.assignTalentPopup){
//     display: flex;
//     align-items: center;
// }
.assignTalentPopup {
    top: 50px !important;
    width: 40vw !important;
    .assignTalentContainer {
        font-family: "Inter";
        .header {
            font-weight: 600;
            font-size: 1.82vw;
            text-align: center;
        }
        .shiftContainer p {
            color: #666666;
            font-size: 1.17vw;
        }
        .timeContainer {
            display: flex;
            justify-content: space-between;
            .selectionBox:first-child {
                margin-right: 1em;
            }
            .selectionBox:last-child {
                margin-left: 1em;
            }
            .selectionBox {
                display: flex;
                flex: 1;
                top: -0.35vw;
                position: relative;
                font-size: 1.04vw;
                color: #333;
                .ant-select-selector {
                    height: 3vw !important;
                    // width: 100rem;
                    display: flex;
                    align-items: center;
                    // .ant-select-selection-search-input{
                    //     height: max-content !important;
                    // }
                }
            }
            .selectionBox:first-child:before {
                content: "Primary Employeer";
                position: absolute;
                top: -1vw;
                color: #666666;
                font-size: 1.17vw;
                left: 0.65vw;
                background-color: white;
                z-index: 5;
                padding: 0 5px;
            }
            .selectionBox:last-child:before {
                content: "Job Role";
                position: absolute;
                top: -1vw;
                color: #666666;
                font-size: 1.17vw;
                left: 0.65vw;
                background-color: white;
                z-index: 5;
                padding: 0 5px;
            }
        }
        .superName {
            margin: 2.4vw 0;
            input[type="text"] {
                outline: none;
                width: 100%;
                padding: 0.5vw;
                font-size: 1.04vw;
                color: #333;
                padding: 6.5px 11px;
                border: 1px solid #d9d9d9;
            }
            position: relative;
        }
        .superName:before {
            content: "Supervisor name";
            position: absolute;
            top: -2vw;
            color: #666666;
            font-size: 1.17vw;

            background-color: white;
            z-index: 5;
            padding: 0px;
        }
        //
        .joblist {
            margin: 3vw 0;
            // input[type="text"] {
            //     outline: none;
            //     width: 100%;
            //     border: 1.5px solid #c6c6c6;
            //     padding: 0.5vw;
            //     font-size: 1.04vw;
            //     color: #333;
                
            // }
            position: relative;
        
        }
        .joblist:before {
            content: "Date Of Joining";
            position: absolute;
            top: -2vw;
            color: #666666;
            font-size: 1.17vw;
            background-color: white;
            z-index: 5;
            padding: 0px;
        }
        //
        .salary {
            margin: 2.4vw 0;
            input[type="text"] {
                outline: none;
                width: 70%;
                border: 1.5px solid #c6c6c6;
                padding: 0.5vw;
                font-size: 1.04vw;
                color: #333;
            }
            position: relative;
            .selectionBox {
                // display: flex;
                // flex: 1;
                // top: -0.35vw;
                left: 1rem;
                // width: 27%;
                position: relative;
                font-size: 1.04vw;
                color: #333;
                .ant-select-selector {
                    height: 2.5rem !important;
                    width: 15rem;
                    display: flex;
                    align-items: center;
                    // .ant-select-selection-search-input{
                    //     height: max-content !important;
                    // }
                }
            }
        }
        .salary:before {
            content: "Job list";
            position: absolute;
            top: -2vw;
            color: #666666;
            font-size: 1.17vw;
            background-color: white;
            z-index: 5;
            padding: 0px;
        }
        img {
            cursor: pointer;
        }
        .fieldListItem {
            margin-bottom: 2.4vw;
            input {
                outline: none;
                border: none;
            }
            .label {
                input {
                    font-family: "Inter";
                    font-size: 1.04vw;
                    // width: 50px;
                    // min-width: 50px;
                    // max-width: 100%;
                    color: #8c8c8c;
                }
            }
            .input {
                display: flex;
                input {
                    width: 100%;
                    border: 1.5px solid #c6c6c6;
                    padding: 0.5vw;
                    font-size: 1.04vw;
                    color: #333;
                    margin-right: 1em;
                }
            }
        }
        .addBtn {
            margin-bottom: 2.4vw;
        }
        .btnContainer {
            display: flex;
            justify-content: center;
            .btn {
                margin: 0 1em;
            }
        }
    }
}