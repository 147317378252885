.tableHead {
    display: grid;
    grid-template-columns: repeat(13, 5fr);
    grid-auto-columns: minmax(70px, auto);
    grid-gap: 2%;
    width: 96vw;
    overflow: auto;
    position: relative;
}
.more {
    position: absolute;
    right: 1vw;
    top: -3.85rem;
    z-index: 5;
}
.more,
.optionList {
    width: max-content;
    float: right;
    cursor: pointer;
    background-color: lightblue;
}
.optionList {
    position: absolute;
    right: 2%;
    min-width: max-content;
    color: #333333;
    padding: 1%;
    z-index: 5;
    max-height: 45vh;
    overflow: auto;
    background-color: white;
    border: 1px solid #c6c6c6;
}
.immovable{
    cursor: none;
    pointer-events: none;
    img{
        visibility: hidden;
        opacity: 0;
    }
}
.listItem {
    :-webkit-user-drag,
    :-moz-drag-over {
        cursor: move;
    }
    // *{
    //     margin: auto 5%;
    // }
    padding: 2% 5%;
    display: flex;
    width: 20vw;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    span {
        white-space: nowrap;
        font-weight: 500;
        font-size: 1.3vw;
        font-family: "inter";
        img {
            padding-right: 0.6rem;
        }
    }
    input {
        cursor: pointer;
        padding: 2%;
    }
}
.canDrop {
    height: 5px;
    background-color: #0361fe;
}
.responsiveTable {
    max-width: 100vw;
    // overflow: auto;
    // height: 40vh;
    position: relative;
    scroll-behavior: smooth;
    .tableWraper {
        max-width: 100vw;
        overflow: auto;
        height: 83vh;
        scrollbar-width: revert-layer;
    }
    table {
        // thead>tr{
        //     height: 50px;
        //     align-items: center;
        // }
        tr:hover > td {
            // background-color: #F4E5FF; //#e8e8e8;
            background-color: #d0e0ff
        }
        input[type="checkbox"] {
            cursor: pointer;
            padding: 2%;
            transform: scale(1.5);
        }
        th:first-child {
            // white-space: nowrap;
            padding-right: 0.5%;
            text-align: center;
            width: 4vw !important;
            min-width: 4vw !important;
            max-width: 4vw !important;
        }
        thead {
            position: relative;
            th {
                position: sticky;
                vertical-align: middle;
                top: 0;
                z-index: 4;
                padding-left: 0.5rem;
                padding-bottom: 0.5em;
                white-space: nowrap;
            }
            tr{
                border-bottom: 1.5px solid #35006B;
            }
        }
        td,
        th {
            min-width: 10rem;
            // position: fixed;
            // top: 0;
            height: 5vh;
            text-align: left;
            color: #808080;
            background-color: white;
            font-weight: 600;
            font-size: 1.1vw;
            .filterWraper {
                position: relative;
                margin: 0.5vw 0;
                img {
                    width: 1em;
                    height: 1em;
                    position: absolute;
                    top: 0.1em;
                    left: 0.1em;
                }
                .clearField{
                    right: 0rem;
                    top: 0.2rem;
                    width: 12px;
                    height: 15px;
                    left: auto;
                }
            }
            input[type="text"],input[type="date"],
            select {
                display: block;
                width: 90%;
                max-width: 20em;
                height: 1.36vw;
                padding: 0%;
                border: 1px solid #666666;
                outline: none;
                font-family: "Roboto";
                font-size: 0.91vw;
                color: #666666;
            }
            input[type="text"],input[type="date"] {
                padding: 0.2rem;
            }
            :last-child {
                column-span: all;
            }
        }
       
        td:last-child,
        thead,
        tbody,
        th:last-child {
            width: 100%;
        }
        // tbody{
        //     overflow-y: scroll;
        //     height: 30vh;
        // }

        tr {
            // margin: 1vw auto;
            cursor: pointer;
            .arrowContainer {
                // display: none;
                position: sticky;
                // border-top: 5px solid white;
                // border-bottom: 5px solid white;
                margin-top: 25%;
                right: 0;
                cursor: pointer;
                img {
                    vertical-align: baseline;
                }
            }
            td:first-child {
                width: 4vw !important;
                min-width: 4vw !important;
                max-width: 4vw !important;
                text-align: center;
            }

            td {
                position: relative;
                padding: 0.5%;
                // padding-left: 0%;
                text-align: left;
                // padding-left: 0%;
                white-space: nowrap;
                // background-color: #e8f2ff;
                background-color:#e8f2ff;
                line-height: 2rem;
                // margin: auto 2%;
                min-width: 10vw;
                max-width: 20vw;
                text-overflow: ellipsis;
                overflow: hidden;
                color: #333333;
                border-bottom: 5px solid white;
                font-size: 0.95vw;
                img {
                    width: 1em;
                    height: 1em;
                }
                // position: relative;
                // .toolTip{
                //     display: none;
                //     z-index: 5;
                //     padding: 2%;
                //     position: relative;
                //     white-space: normal;
                //     color: white;
                //     background-color: #262626;
                // }
            }
            // td:hover .toolTip{
            //     display: flex;
            // }
        }
    }
}

.label_text{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin-top: 20px;
    color: #666666;
}
.logo_img{
    width: 2.5em !important;
    height: 2.5em !important;
    border-radius: 4px;
}
.upload_content{
    display: flex;
    height: 33px;
    width: 100px;
    border: 2px dashed #C6C6C6;
    justify-content: space-between;
    padding: 3px 8px;
    border-radius: 6px;
    // position: absolute;
    // bottom: 115px;
}
.upload_img{
    width: 25px;
    height: 20px;
}
.upload_btn{
    position: relative;
}
.input_type_file{
    opacity: 0;
    position: absolute;
    height: 40px;
    width: 100px;
    z-index: 2;
    cursor: pointer;
}
.span_first{
    color: #F44D4D;
}
.span_class{
    font-family: 'Inter';
    font-style: normal;
    font-size: 13px;
    font-weight: 200;

}
.span_second{
 
color: #666666;
}
.span_error{
    font-weight: 400;

    color: #F44D4D;

}
.invalid_upload{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 13px;
margin-left: 23%;

color: #F44D4D;
}
.upload_container{
    // position: absolute;
    display: flex;
    bottom: 124px;
    width: 17vw;
    height: 27px;
    justify-content: space-between;
}
.is_upload_img{
    display: flex;
    width: 7vw;
    justify-content: space-between;
}