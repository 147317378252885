.contractor-details-container {
    height: calc(100vh - 120px);
    overflow-y: auto;
    padding: 20px;
}

.contractor-details-container::-webkit-scrollbar {
    width: 8px;
}

.contractor-details-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.contractor-details-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.contractor-details-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}


.responsiveTable2 {
    th.ant-table-cell{
        border-bottom: 2px solid black !important;
    }
    max-width: 100vw !important;
    position: relative !important;
    scroll-behavior: smooth !important;

    .tableWraper {
        max-width: 100vw !important;
        overflow: auto !important;
        height: 83vh !important;
    }

    table {

        tr:hover>td {
            background-color: #d0e0ff
        }

        input[type="checkbox"] {
            cursor: pointer !important;
            padding: 2% !important;
            transform: scale(1.5) !important;
        }

        

        thead {
            position: relative !important;

            th {
                position: sticky !important;
                vertical-align: middle !important;
                top: 0 !important;
                z-index: 4 !important;
                padding-left: 0.5rem !important;
                padding-bottom: 0.5em !important;
                white-space: nowrap !important;
            }

            tr {
                border-bottom: 1.5px solid #35006B !important;
            }
        }

        td,
        th {
            min-width: 10rem !important;


            height: 5vh !important;
            text-align: left !important;
            color: #808080 !important;
            background-color: white !important;
            font-weight: 600 !important;
            font-size: 1.1vw !important;

            .filterWraper {
                position: relative !important;
                margin: 0.5vw 0 !important;

                img {
                    width: 1em !important;
                    height: 1em !important;
                    position: absolute !important;
                    top: 0.1em !important;
                    left: 0.1em !important;
                }

                .clearField {
                    right: 0rem !important;
                    top: 0.2rem !important;
                    width: 12px !important;
                    height: 15px !important;
                    left: auto !important;
                }
            }

            input[type="text"],
            input[type="date"],
            select {
                display: block !important;
                width: 90% !important;
                max-width: 20em !important;
                height: 1.36vw !important;
                padding: 0% !important;
                border: 1px solid #666666 !important;
                outline: none !important;
                font-family: "Roboto" !important;
                font-size: 0.91vw !important;
                color: #666666 !important;
            }

            input[type="text"],
            input[type="date"] {
                padding: 0.2rem !important;
            }

            :last-child {
                column-span: all !important;
            }
        }


        td:last-child,
        thead,
        tbody,
        /* th:last-child {
            width: 100% !important;
        } */





        tr {

            cursor: pointer !important;

            .arrowContainer {

                position: sticky !important;


                margin-top: 25% !important;
                right: 0 !important;
                cursor: pointer !important;

                img {
                    vertical-align: baseline !important;
                }
            }

            
            td {
                position: relative !important;
                padding: 0.5% !important;

                text-align: left !important;

                white-space: nowrap !important;

                background-color: #e8f2ff !important;
                line-height: 2rem !important;

                min-width: 10vw !important;
                max-width: 20vw !important;
                text-overflow: ellipsis !important;
                overflow: hidden !important;
                color: #333333 !important;
                border-bottom: 5px solid white !important;
                font-size: 0.95vw !important;

                img {
                    width: 1em !important;
                    height: 1em !important;
                }
            }



        }
    }
}

.contractor-form {
    input {
        border: 1.5px solid #c6c6c6;
    }
    .ant-form-item {
        padding: 4.8px;
        margin: 0;
    }
}

.modalHeader {
    text-align: center;
    font-size: 1.65rem;
    font-weight: 600;
}



.contractor-details-container {
    padding: 2rem;
    background-color: #f5f5f5;
}

.contractor-form {
    background: white;
    padding: 2rem;
    border-radius: 8px;
}

.Form{
    width: 100% !important;
    padding-left: 3rem;
    padding-right: 3rem;
}

.form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid #f0f0f0;
}

@media (max-width: 768px) {
    .contractor-details-container {
        padding: 1rem;
    }

    .contractor-form {
        padding: 1rem;
    }
}